import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route,Routes,Navigate} from 'react-router-dom'
import React, { useEffect, useState } from 'react';

import { getAuth,onAuthStateChanged} from "firebase/auth";
import Home from './screens/Home';
import Header from './components/Header';
import Dashboard from './screens/Dashboard';
import Login from './screens/Login';
import BuyClasses from './screens/BuyClasses';
import ContactUs from './screens/ContactUs';
import AboutUs from './screens/AboutUs';
import Features from './screens/Features';

function App() {

  const auth = getAuth();

  const [selectedRoute, setSelectedRoute] = useState('home')
  const [loggedIn, setLoggedIn] = useState(false)
  const [onSignIn, setOnSignIn] = useState(false)

  const [priceClicks, setPriceClicks] = useState(0)
  const [featureClicks, setFeatureClicks] = useState(0)


  const routeChange = (route) => {
    console.log("Route changed",route)
    setSelectedRoute(route)
    setOnSignIn(route==='auth')
    
  }

  // useEffect(() => {

  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       console.log("Auth change", true,user,loggedIn)
  
  //       setLoggedIn(true)
  //       setOnSignIn(false)
        
  
  //     } else {
  //       console.log("Auth change", false,loggedIn)
  //       let wasLoggedIn = (loggedIn===true)
  //       setLoggedIn(false)
  //       if(wasLoggedIn){setOnSignIn(true)}
  //     }
  //   })
  //   return () => unsubscribe()
  
  //   },[loggedIn])

  
  const headerPriceClicked = () => {
    
    setPriceClicks(priceClicks+1)
  }

  
  const headerFeatureClicked = () => {
    
    setFeatureClicks(featureClicks+1)
  }

  
  const getHeader = () =>{
    return <Header selectedRouteG={selectedRoute} loggedIn={loggedIn} routeChanged={routeChange} priceSelected={headerPriceClicked} featureSelected={headerFeatureClicked}/>
  }




  return (
    <div className="App">
       <Router className="Router">

          {getHeader()}

          <Routes>
            <Route path='/'  element={<Home priceClicks={priceClicks} featureClicks={featureClicks}/>} />
            <Route path='/about'  element={<AboutUs />} />
            <Route path='/contact'  element={<ContactUs />} />
            <Route path='/features'  element={<Features />} />
            {/* <Route path='/dashboard'  element={<Dashboard />} />
            <Route path='/auth'  element={<Login />} />
            <Route path='/buy'  element={<BuyClasses />} /> */}


            {/* <Route path='/logout'  element={loggedIn?<LogOut />:<AuthTest/>} /> */}
            {/* <Route path='/home'  element={<Home/>} /> */}

          </Routes>



        </Router>
    </div>
  );
}

export default App;
