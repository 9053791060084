import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../styles/header.css'


export default function Header({selectedRouteG,loggedIn,routeChanged, priceSelected,featureSelected}) {


    const [selectedRoute,setSelectedRoute] = useState(selectedRouteG)
    const [optionsOpen,setOptionsOpen] = useState(false)
    const [userName, setUserName] = useState(" ")
    const [isTutor,setIsTutor]  = useState(false)


    const [expanded, setExpanded] = useState(false)


    const navigate = useNavigate();
    const delay = ms => new Promise(res => setTimeout(res, ms));


    const getUser = async() => {
        await delay(500)
        if(localStorage.getItem("user") === null){getUser()}
        else{
            let user = JSON.parse(localStorage.getItem("user"))
            console.log("THE USER",user)
            if(userName === " "){setUserName(user.name)}
            setIsTutor(user.isTutor)
        }
        
    }

    if(loggedIn){

        
        getUser()
        
    }

    


    const routeSelected = (route) => {

        setOptionsOpen(false)
        setSelectedRoute(route)
        routeChanged(route)
        navigate(`../${route}`, { replace: true });
    }

    const optionsClicked = () =>{
        setOptionsOpen(!optionsOpen)
    }

    const logout = () => {
        const auth = getAuth();
        
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            localStorage.removeItem("user")
            localStorage.removeItem("lessons")
            navigate("../auth", { replace: true });
          }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
          });
    }

    useEffect(() => {

        setSelectedRoute(selectedRouteG)

    },[selectedRouteG])

    const priceClicked = () => {
        setSelectedRoute("Home")
        navigate("../", { replace: true });
        priceSelected()
    }

    const featureClicked = () => {
        setSelectedRoute("Home")
        navigate("../", { replace: true });
        featureSelected()
    }
    
    const getConsultation = () => {
        const newWindow = window.open('https://calendly.com/raunaqbagga', '_blank', 'noopener,noreferrer')
    	if (newWindow) newWindow.opener = null
    }

    return(


        <header className="py-4 md:py-6" x-data="{expanded: false}">
        <div className="container px-4 mx-auto sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
                <div className="flex-shrink-0">
                    <a href="/" title="" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                        <img className="w-auto h-8" src="/assets/routeenLogo.png" alt="" />
                    </a>
                </div>

                <div className="flex lg:hidden">
                    <button type="button" className="text-gray-900" onClick={()=>setExpanded(!expanded)} >
                        {!expanded && <span x-show="!expanded" aria-hidden="true">
                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </span>}

                        {expanded && <span x-show="expanded" aria-hidden="true">
                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>}
                    </button>
                </div>

                <div className="hidden lg:flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10 xl:space-x-16">
                    {/* <a href="/about" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> About </a> */}

                    <div onClick={featureClicked} title="" className="cursorPointer text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Features </div>

                    <div onClick={priceClicked} title="" className="cursorPointer text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Pricing </div>

                    <a href="/contact" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Contact Us </a>
                </div>

                <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-10">
                    {/* <a href="#" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Customer Login </a> */}

                    <a
                        onClick={getConsultation}
                        title=""
                        className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 routeenBlueBtn"
                        role="button"
                    >
                        Book Free Demo
                    </a>
                </div>
            </div>

            {expanded &&<nav x-show="expanded" x-collapse>
                <div className="px-1 py-8">
                    <div className="grid gap-y-7">
                        <a href="/about" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> About </a>

                        <a href="/features" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Features </a>

                        <a href="/contact" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Contact </a>

                        

                        <a
                            onClick={getConsultation}
                            title=""
                            className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 routeenBlueBtn"
                            role="button"
                        >
                            Book Free Demo
                        </a>
                    </div>
                </div>
            </nav>}
        </div>
    </header>





        // <div className="header">

        //     <div className="headerRow">

        //         <div className="routesRow">
        //             <img className="logoHeader" src="/assets/routeenLogo.png" onClick={()=>routeSelected('')}/>

        //             <div className={`headerRoutesRow ${optionsOpen?"":"routesClosed"}`}>
        //                 <span className={`headerRoute ${selectedRoute=='home'?'selected':''}`} onClick={()=>routeSelected('')}>Home</span>
        //                 <span className={`headerRoute ${selectedRoute=='about'?"selected":""}`} onClick={()=>routeSelected('about')}>About Us</span>
        //                 <span className={`headerRoute ${selectedRoute=='features'?"selected":""}`} onClick={()=>routeSelected('features')}>Features</span>
        //                 <span className={`headerRoute ${selectedRoute=='faq'?"selected":""}`} onClick={priceClicked}>Pricing</span>
        //                 <span className={`headerRoute ${selectedRoute=='contact'?"selected":""}`} onClick={()=>routeSelected('contact')}>Contact Us</span>
        //                 {loggedIn && <span className={`headerRoute ${selectedRoute=='dashboard'?"selected":""}`} onClick={()=>routeSelected('dashboard')}>Dashboard</span>}
        //             </div>
                    


        //         </div>

        //         {/* <img  className="headerImg" onClick={optionsClicked}  src={optionsOpen?"assets/headerCancel.png":"assets/headerBurger.png"}/> */}

        //         <div  className={`headerImg ${optionsOpen?"open":""}`}onClick={optionsClicked} />

                

        //         {/* <div className="buttonsRowHeader">
                    
        //             <div className="verticalLine"/>
        //             {(!loggedIn && !onSignIn) && <button className="headerBtn login" onClick={()=>routeSelected('auth')}>Login</button>}
        //             {(!loggedIn  && !onSignIn) && <button className="headerBtn join">Join Now</button>}

        //             {loggedIn && <div className="headerProfile">
        //                 <div className="headerProfileTitle" onClick={optionsClicked}>
        //                     <span className="headerProfilePic">{userName[0]}</span>
        //                     <span>{userName}</span>
        //                     <img className="headerProfileDown" src="/assets/arrowDown.png"/>
        //                 </div>
                        
        //                 {optionsOpen &&<div className="headerProfileOptions" style={{ backgroundImage: "url('//assets/speechBubble.png')" }}>
        //                     <span className="headerProfileOption" onClick={()=>routeSelected('dashboard')}>View Dashboard</span>
        //                     <span className="headerProfileOption">Settings</span>
        //                     <span className="headerProfileOption" onClick={logout}>Logout</span>
        //                 </div>}
        //             </div>}

        //         </div> */}


                

        //     </div>


        // </div>
    )
}


