import React, { useEffect, useRef, useState } from 'react';
import '../styles/form.css'
import axios from "axios";

export default function FormPopUp({closePopUp}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [nameText, setNameText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [websiteText, setWebsiteText] = useState("")
    const [noteText, setNoteText] = useState("")
    

    const [submitted, setSubmitted] = useState(false)
  
    
    const sendMessage = () =>
    {
        
        axios.post(baseURL+'routeenEnquiry',{
            name:nameText,
            email:emailText,
            message:noteText,
            source:"White Label Info Site - Sign Up Form",
            website:websiteText
        }).then((response) => {
            let resVar = response.data

            setSubmitted(true)
            
            console.log("Email", resVar)
        })
    }

  
  

    return (
        <div className='formShadow'>
            <div className={`formPopUp ${submitted?'submitted':''}`}>

                <div className='formTop'>
                    <img className='formPopUpLogo' src='/assets/routeenLogo.png'/>
                    <img className='formPopUpCancel' src='/assets/cross.png' onClick={closePopUp}/>
                </div>
                
                
                {!submitted && <div className='fpuContent'>
                    <div className='fpuTitles'>
                        <span className='fpuTitle'>Thank you for your interest in Routeen</span>
                        <span>Please fill the form below, and we'll reach out to you!</span>
                    </div>
                    <div className='fpuInputs'>
                        <input className="formInput" type="text" placeholder="Name:" value={nameText} onChange={(e) => setNameText(e.target.value)}/>
                        <input className="formInput" type="text" placeholder="Email:" value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                        <input className="formInput" type="text" placeholder="Website (if any):" value={websiteText} onChange={(e) => setWebsiteText(e.target.value)}/>
                        <textarea className="formInput notes" type="text" placeholder="Additional Notes:" value={noteText} onChange={(e) => setNoteText(e.target.value)}/>
                    </div>
                </div>}
                
                {submitted && <div className='formSubmitted'>
                    <span className='fpuTitle'>Thank you!</span>
                    <span>A representative should be reaching out to you soon.</span>
                </div>}
                
                {!submitted && <div className='formRightAlign'><button className='formPopUpSubmit' disabled={nameText==="" || emailText===""} onClick={sendMessage}>Submit</button></div>}
            </div>
        </div>
    )
}