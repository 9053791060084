import React, { useEffect, useRef, useState } from 'react';
import '../styles/form.css'
import axios from "axios";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentComponent from './PaymentComponent';


export default function NewFormPopUp({closePopUp,price}) {

    const stripePromise = loadStripe('pk_test_51LuBnCSC11nmIG70NAqhpNx8k46sgAMlHTUxxktI6fqaQgc1tWYMY98CffqLuNU66Os0L83N5mFFdy9kNdWkrkwv00ZvUnRdKr',
    // {stripeAccount:'acct_1LuK1cC7dQuPBvhw'}
    );

    const baseURL = process.env.REACT_APP_BASEURL;

    const [nameText, setNameText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [phoneText, setPhoneText] = useState("")
    const [websiteText, setWebsiteText] = useState("")
    const [noteText, setNoteText] = useState("")
    
    const [submitted, setSubmitted] = useState(false)

    const [showPayment, setShowPayment] = useState(false)
  
    const [setupIntentID, setIntentID] = useState("")
    const [checkingPayment,setCheckingPayment] = useState(false)
    const [options, setOptions] = useState(null)
    const [showPaySuccess,setShowPaySuccess] = useState(false)
    

    const appearance = {
        theme: 'flat',
        variables:{
            
        }
        
      };


    const sendMessage = () =>
    {
        
        axios.post(baseURL+'routeenEnquiry',{
            name:nameText,
            email:emailText,
            message:noteText,
            source:"White Label Info Site - Sign Up Form",
            website:websiteText
        }).then((response) => {
            let resVar = response.data


            
            setShowPayment(true)
            createStripeCustomer()
            
            console.log("Email", resVar)
        })
    }

    
    const createStripeCustomer = () => {
  
  
        axios.get(baseURL+'createStripeCustomer',{
            params:{
                name: nameText,
                email: emailText
            }
        }).then( (response) => {
        
          const data = response.data;
          
            let cusID = data.customerID
            createPaymentIntent(cusID)
        
        });

    }

    
    const createPaymentIntent = (cusID) => {
  
  
        axios.get(baseURL+'createPaymentIntent',{
            params:{
                customer: cusID,
                amount: price
            }
        }).then( (response) => {
        
          const data = response.data;
          
          let setUpID = data.paymentIntent.client_secret
          console.log("Setup ID",setUpID)

          

          setOptions({clientSecret:setUpID,appearance:appearance})
            
          setIntentID("")
          setIntentID(setUpID)
        
        });

    }

    const stripeResponse = (res)=>
    {
        if(res !== "Success"){setCheckingPayment(false)
                // setLoadingText("Checking card")
        }
        else{
            onSuccessfulPayment()
        }
    }


    const onSuccessfulPayment = async () => {

        console.log("SUCCESSFUL PAYMENT")
        setShowPaySuccess(true)
        // closePopUp()

    }

  
  

    return (
        <div className='formShadow'>
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 whiteBkg">
            <img className='newformPopUpCancel' src='/assets/cross.png' onClick={closePopUp}/>
        <div class="mx-auto max-w-lg">
            <h1 class="text-center text-2xl font-bold text-indigo-600 sm:text-3xl routeenBlueTxt">
            Thanks for choosing Routeen
            </h1>

            <p class="mx-auto mt-4 max-w-md text-center text-gray-500">  
                Please fill your details below to be prompted to our  secure payment gateway.
                <br />Note: Our onboarding executive will reach out to you within 24 hours of your signup.  
            </p>

            {!showPayment && <div action="" class="mt-6 mb-0 space-y-4 rounded-lg p-8 shadow-2xl">
            {/* <p class="text-lg font-medium">Sign in to your account</p> */}
            <div>
                <div for="name" className="text-sm font-medium popUpInputTitle">Name</div>

                <div class="relative mt-1">
                <input
                    type="text"
                    id="name"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="Enter name"
                    value={nameText} onChange={(e) => setNameText(e.target.value)}
                />
                </div>
            </div>


            <div>
                <div for="email" className="text-sm font-medium popUpInputTitle">Email</div>

                <div class="relative mt-1">
                <input
                    type="email"
                    id="email"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="Enter email"
                    value={emailText} onChange={(e) => setEmailText(e.target.value)}
                />

                <span class="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                    </svg>
                </span>
                </div>
            </div>

            <div>
                <div for="linkedIn" className="text-sm font-medium popUpInputTitle">{"LinkedIn (Optional)"}</div>

                <div class="relative mt-1">
                <input
                    type="linkedIn"
                    id="linkedIn"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="Enter linkedIn"
                    value={websiteText} onChange={(e) => setWebsiteText(e.target.value)}
                />
                </div>
            </div>

            <div>
                <div for="linkedIn" className="text-sm font-medium popUpInputTitle">Phone Number</div>

                <div class="relative mt-1">
                <input
                    type="number"
                    id="linkedIn"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="Enter phone number"
                    value={phoneText} onChange={(e) => setPhoneText(e.target.value)}
                />
                </div>
            </div>


            <div>
                <div for="linkedIn" className="text-sm font-medium popUpInputTitle">Preferred contact method or additional information</div>

                <div class="relative mt-1">
                <textarea
                    type="linkedIn"
                    id="linkedIn"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="eg. Phone"
                    value={noteText} onChange={(e) => setNoteText(e.target.value)}
                />
                </div>
            </div>



            {/* <div>
                <label for="password" class="text-sm font-medium popUpInputTitle">Password</label>

                <div class="relative mt-1">
                <input
                    type="password"
                    id="password"
                    class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                    placeholder="Enter password"
                />

                <span class="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                    </svg>
                </span>
                </div>
            </div> */}

            <button
                // type="submit"
                class="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white routeenBlueBtn"
                disabled={nameText==="" || emailText===""} 
                onClick={sendMessage}
            >
                Proceed to payment
            </button>

            <p class="text-center text-sm text-gray-500">
                Still in doubt? Contact our sales team for more info or
                <a class="underline" href="">{" a demo"}</a>
            </p>
            </div>}

            {(showPayment && !showPaySuccess) && <div>
                
                {(setupIntentID) && 
                <Elements stripe={stripePromise} options={options} >
                    <PaymentComponent stripeResponse={stripeResponse}  submitClicked={checkingPayment} isSetup={false} savedCard ={{secret:options.clientSecret}}/>
                </Elements>
                }
                {(!setupIntentID) && 
                    <div className='tempText'>Loading Payment....</div>
                }
            </div>}

            {showPaySuccess && <div className='tempText'>
                SUCCESSFUL PAYMENT
            </div>}
        </div>
        </div>
        </div>
    )
}