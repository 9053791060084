import '../styles/home.css'


export default function FeatureScreenTile({number}) {



    return (
        <div className='homeScreenStep'>
            
            {number===1 && <span className='hssTitle'>Better Yet More Affordable</span>}
            {number===2 && <span className='hssTitle'>Landing and Info Pages</span>}
            {number===3 && <span className='hssTitle'>Student Engagement</span>}
            
            <div className='hssLine'/>

            
            {number===1 && <span className='hssContent'>While routeen provides you with far more than a traditional website, we only charge you a fraction of the fee, with plans starting at only $49.99</span>}
            
            {number===2 && <span className='hssContent'>Your routeen site includes a landing page and a page all about you, so your students have a place to discover you without taking up your time, with an option to add a pre recorded demo class as well. </span>}
            
            {number===3 && <span className='hssContent'>Routeen provides you with multiple ways to keep your students engaged and up to date with their work, including a practice platform* which helps you know their weak points best, and helps them prepare better. </span>}
            
            
        </div>

    )
}