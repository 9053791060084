import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from 'react';

import '../styles/about.css'
import TeamMember from "../components/TeamMember";
import FormPopUp from "../components/FormPopUp";

export default function AboutUs() {

    const navigate = useNavigate();

    const [showPopUp, setShowPopUp] = useState(false)


    return(
        <div className="aboutScreen">

            <div className="aboutScreenContainer">


                <div className="aboutSection1">
                    <div className="aboutSec1Column">
                        <span className="aboutSec1Title">Building the future of education <span className="homeScreenBold">Routeen-ly.</span></span>
                        <span className="aboutSec1Body">The pandemic left us all with the inception of a new form of education, primarily conducted online, but far too often, even the most experianced teachers can often get swayed by extremely expensive platforms with next to no functionality, and after coming across too many of our own teachers being victims to this, we decided to step up and build the solution, which led to the birth of routeen</span>
                    </div>
                    <img className="aboutSec1Img" src="/assets/homeMap.png"/>
                </div>

                <div className="aboutSection2">
                    <span className="aboutSubTitle">Meet our team</span>
                    <div className="aboutTeamRow">
                        <TeamMember/>
                        <TeamMember/>
                        <TeamMember/>
                    </div>
                </div>


                <div className='homeScreenSec7'>
                    <div className='homeScreenSec7Titles'>
                        <span>Grow your brand.</span>
                        <span className="homeScreenBold">Routeen-ly</span>
                    </div>

                    <button className='homeScreenSec7Btn' onClick={()=>setShowPopUp(true)}>Get Started</button>
                </div>     

                {showPopUp && <FormPopUp closePopUp={()=>setShowPopUp(false)}/>}

                

            </div>

        </div>
    )
}


