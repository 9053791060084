import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from 'react';

import '../styles/features.css'
import TeamMember from "../components/TeamMember";
import FormPopUp from "../components/FormPopUp";
import axios from "axios";
import FeatureScreenTile from "../components/FeatureScreenTile";

export default function Features() {
    const baseURL = process.env.REACT_APP_BASEURL;

    const navigate = useNavigate();

    const [showPopUp, setShowPopUp] = useState(false)
    const [emailText,setEmailText] = useState('')

    
    const emailBtnClicked = () => {
     
        if(emailText.includes("@"))
        {
            setEmailText("")
            sendMessage()
        }
    }

    
    const sendMessage = () =>
    {
        
        axios.post(baseURL+'routeenEnquiry',{
            name:"N/A",
            email:emailText,
            message:"N/A",
            source:"White Label Info Site - Home Screen"
        }).then((response) => {
            let resVar = response.data
            
            console.log("Email", resVar)
        })
    }

    return(
        <div className="featureScreen">

            <div className="featureScreenContainer">


                <div className='featureScreenSec1'>

                    <div className="featureSecContent">

                        <img className='featuresScreenImg1'  src='/assets/homeMic.png'/>

                        <div className='featureScreenSec1Content'>
                            <div className='homeScreenTitles'>
                                <span>Focus on what matters, and automate the rest</span>
                                <span className="homeScreenBold">Routeen-ly.</span>
                            </div>
                            

                            <div className='featureScreenContact'>
                                <input type="text" className='homeScreenContactInput' placeholder='Your email here' value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                                <button className='homeScreenContactBtn' onClick={emailBtnClicked} disabled={emailText===""}>Get in touch</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="featureScreenSec2">

                    <div className="featureSecContent">

                        <div className="featureSec2Column">
                            <div className="featureTextColumn">
                                <span className="featureTextTitle">Pre Payments</span>
                                <span>Say goodbye to the days of having to chase people for payments, with automatic payment collection before a student can schedule a class with you</span>
                            </div>
                            <img className="featuresScreenImg2" src="/assets/stripeLogo.png"/>
                        </div>
                        
                        <img className="featuresScreenImg3" />

                    </div>

                </div>
                

                <div className="featureScreenSec3">

                    <div className="featureSecContent">
                        <img className="featuresScreenImg4" src="/assets/featureImg4.png"/>
                        <div className="featureTextColumn">
                            <span className="featureTextTitle">Automated Scheduling</span>
                            <span>Using our proprietary scheduling systems, routeen takes care of helping your students finalise class timings, while all you have to do is let us know when you’re free, and we’ll take care of the rest</span>
                        </div>
                    </div>

                </div>


                <div className="featureScreenSec4">
                    <div className="featureSecContent column">
                        <span className="featuresSec4Title">The Best Part? It’s All No-Code</span>
                        <span>We’ll take care of all the technical stuff for you</span>
                    </div>
                </div>


                <div className="featureScreenSec5" >
                    <div className="featureSecContent ">
                        <div className="featureTextColumn">
                            <span className="featureTextTitle">Goodbye Meeting Links</span>
                            <span>Video conferencing apps can be expensive, and take tons of your time,But with routeen, all you have to do is click a button, and we take care of it all, from scheduling the class, to hosting it.</span>
                        </div>
                        <img className="featuresScreenImg5" src='/assets/featureImg5.png'/>
                    </div>
                </div>

                <div className="featureSceenSec6">
                    <div className="featureSecContent">
                        <FeatureScreenTile number={1} />
                        <FeatureScreenTile number={2} />
                        <FeatureScreenTile number={3} />
                    </div>
                </div>


                <div className='homeScreenSec7'>
                    <div className='homeScreenSec7Titles'>
                        <span>Grow your brand.</span>
                        <span className="homeScreenBold">Routeen-ly</span>
                    </div>

                    <button className='homeScreenSec7Btn' onClick={()=>setShowPopUp(true)}>Get Started</button>
                </div>     

                {showPopUp && <FormPopUp closePopUp={()=>setShowPopUp(false)}/>}

                

            </div>

        </div>
    )
}


