import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from 'react';
import axios from "axios";

import '../styles/contact.css'


export default function ContactUs() {

    const navigate = useNavigate();

    const baseURL = process.env.REACT_APP_BASEURL;


    const [nameText,setNameText] = useState("")
    const [emailText,setEmailText] = useState("")
    const [messageText,setMessageText] = useState("")

    const [nameError,setNameError] = useState(false)
    const [emailError,setEmailError] = useState(false)
    const [messageError,setMessageError] = useState(false)

    const [submitted,setSubmitted] = useState(false)

    const linkClicked = (link) => {

        switch(link){
            case 'Instagram':
                window.open('https://www.instagram.com/routeentuitions/?igshid=YmMyMTA2M2Y%3D')
                break;
            case 'LinkedIn':
                window.location.href = 'https://www.linkedin.com/company/routeen/';
                break;
            case 'YouTube':
                window.location.href = 'https://youtube.com';
                break;
            case 'WhatsApp':
                window.location.href = 'https://whatsapp.com';
                break;


        }
        

    }


    const submitClicked = () => {
        var error = false

        setNameError(false)
        setEmailError(false)
        setMessageError(false)

        if(nameText == ""){error = true;setNameError(true)}
        if(emailText == ""){error = true;setEmailError(true)}
        if(messageText == ""){error = true;setMessageError(true)}

        if(!error){
            setSubmitted(true)
            sendMessage()
        }
        else{

        }
    }

    const sendMessage = () =>
    {
        
        axios.post(baseURL+'routeenEnquiry',{
            name:nameText,
            email:emailText,
            message:messageText,
            source:"White Label Info Site - Contact Us Screen"
        }).then((response) => {
            let resVar = response.data
            
            console.log("Email", resVar)
        })
    }


    return(
        <div className="contactScreen">

            <div className="contactScreenContainer">

                <img className="contactImg" src="assets/contactImg1.png"/>

                <div className="contactColumn">
                    <span className="contactTitle">Contact Us</span>
                    {!submitted && <div className="contactInputs">
                        <input className={`inputContact ${nameError?"error":""}`} type="text" placeholder="Full Name" value={nameText} onChange={(e) => setNameText(e.target.value)}></input>
                        <input className={`inputContact ${emailError?"error":""}`} type="text" placeholder="Email" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
                        <input className={`inputContact ${messageError?"error":""}`} type="text" placeholder="Message" value={messageText} onChange={(e) => setMessageText(e.target.value)}></input>
                    </div>}
                    {submitted &&<div className="contactInputSubmitted">
                        <img className="contactSubmittedImg" src="/assets/submittedTick.png"/>
                        <span>Thank you! A representative will get in touch with you soon</span>
                    </div>}
                    <button className="contactButton" onClick={submitClicked}>Send Message</button>

                </div>

                <div className="contactColumn2">
                    <span className="contactSubTitle">For Support:</span>
                    <span>{"Email:  support@getrouteen.com"}</span>
                    <span>{"WhatsApp: +91 9830005957"}</span>

                    {/* <span className="contactSubTitle">For Career Related Inquiries:</span>
                    <span>careeers@routeentuitions.com</span> */}

                    <div className="contactRow">
                        <img className="contactIcon" src="assets/contactIG.png" onClick={() => linkClicked('Instagram')}/>
                        <img className="contactIcon" src="assets/contactLI.png" onClick={() => linkClicked('LinkedIn')}/>
                        {/* <img className="contactIcon" src="assets/contactYT.png" onClick={() => linkClicked('YouTube')}/>
                        <img className="contactIcon" src="assets/contactWA.png" onClick={() => linkClicked('WhatsApp')}/> */}
                    </div>
                </div>

            </div>

        </div>
    )
}


